import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import registrationicon from "../../../../assets/icons/login/mobileicon.svg";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { stockAPI } from "../../../../common/axiosInstance";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import { apiData } from "../../../../common/common-types";
import {
  setStorageItem,
  getStorageItem,
  removeStorageItem,
} from "../../../../common/localStorage";
import selected from "../../../../assets/icons/registration/checked_checkbox.svg";
import disable_checkbox from "../../../../assets/icons/registration/disabled_checkbox.svg";
import { InputBase, FormControl, Select, Dialog, InputLabel, TextField } from "@material-ui/core";
import down_arrow from "../../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import { capitalize, isPWAInstalled, customerLoginLogs } from "../../../../common/commonFunctions";
import { grey } from "@material-ui/core/colors";
import { Fragment } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { color } from "../../../../components/UI/Theme";
import { encrypt } from "../../../../crypto-helper";

const styles = (theme) => {
  return {
    wrapper: {
      objectFit: "contain",
      backgroundColor: theme.palette.cards.backgroundColor,
      maxWidth: "660px",
      width: "100%",
      minHeight: "100%",
      margin: "0 auto",
      padding: "40px 30px",
      // margin: "150px 0px 66px",
      textAlign: "center",
      [theme.breakpoints.down(641)]: {
        padding: "40px 28px",
        // margin: "139px 0px 63px",
      },
      [theme.breakpoints.down(481)]: {
        padding: "40px 21px",
        // margin: "55px 0px 40px",
      },
      [theme.breakpoints.down(381)]: {
        padding: "40px 15px",
        // margin: "38px 0px 34px",
      },
      [theme.breakpoints.down(321)]: {
        padding: "40px 13px",
        // margin: "69px 0px 0px",
      },
    },
    heading: {
      fontSize: "32px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "0.94",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.typography.texts.color,
      marginTop: "120px",
      marginBottom: "85px",
      [theme.breakpoints.down(641)]: {
        fontSize: "28px",
        lineHeight: "0.96",
        marginTop: "105px",
        marginBottom: "75px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "18px",
        lineHeight: "0.91",
        marginTop: "78px",
        marginBottom: "56px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "17.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "16.5px",
        lineHeight: "0.83",
        marginTop: "57px",
        marginBottom: "41px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "14px",
        lineHeight: "0.84",
        marginTop: "50px",
        marginBottom: "37px",
      },
    },
    icons: {
      width: "100%",
      height: "122px",
      "--size ": "70px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        // width: "69px",
        height: "122px",
      },
      [theme.breakpoints.down(641)]: {
        // width: "63px",
        height: "109px",
      },
      [theme.breakpoints.down(481)]: {
        // width: "46px",
        height: "82px",
      },
      [theme.breakpoints.down(381)]: {
        // width: "35px",
        height: "62px",
      },
      [theme.breakpoints.down(321)]: {
        // width: "30px",
        height: "54px",
      },
    },
    textWrap: {
      width: "100%",
      margin: "0px auto",
      clear: "both",
      overflow: "hidden",
    },
    textFname: {
      borderRadius: "5px",
      border: "solid 1px #e4e4e4",
      margin: "0px 2% 20px 0px",
      display: "block",
      width: "49%",
      padding: "10px 0 0 0",
      float: "left",
      "&& input": {
        fontSize: "20px",
      },
      [theme.breakpoints.down(641)]: {
        "&& input": {
          fontSize: "16px",
        },
      },
      [theme.breakpoints.down(481)]: {
        "&& input": {
          fontSize: "14px",
        },
      },
      [theme.breakpoints.down(421)]: {
        "&& input": {
          fontSize: "13.5px",
        },
      },
      [theme.breakpoints.down(401)]: {
        "&& input": {
          fontSize: "13px",
        },
      },
      [theme.breakpoints.down(381)]: {
        "&& input": {
          fontSize: "12.5px",
        },
      },
      [theme.breakpoints.down(361)]: {
        "&& input": {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down(321)]: {
        "&& input": {
          fontSize: "10px",
        },
      },
      "& label": {
        paddingLeft: "14px",
        top: "0",
      },
      "& div": {
        margin: "0px",
        height: "100%",
        padding: "0px",
        width: "92%",
        [theme.breakpoints.down(533)]: {
          width: "90%",
        },
        [theme.breakpoints.down(480)]: {
          width: "88%",
        },
        [theme.breakpoints.down(390)]: {
          width: "87%",
        },
        [theme.breakpoints.down(360)]: {
          width: "84%",
        },
      },
      "&& p": {
        marginTop: "1px",
      },
      "&& div:before": {
        borderBottom: "none",
      },
      "&& div:hover": {
        borderBottom: "none",
      },
    },
    textLname: {
      borderRadius: "5px",
      border: "solid 1px #e4e4e4",
      margin: "0px 0px 20px 0px",
      display: "block",
      width: "49%",
      padding: "10px 0 0 0",
      float: "left",
      "&& input": {
        fontSize: "20px",
      },
      [theme.breakpoints.down(641)]: {
        "&& input": {
          fontSize: "16px",
        },
      },
      [theme.breakpoints.down(481)]: {
        "&& input": {
          fontSize: "14px",
        },
      },
      [theme.breakpoints.down(421)]: {
        "&& input": {
          fontSize: "13.5px",
        },
      },
      [theme.breakpoints.down(401)]: {
        "&& input": {
          fontSize: "13px",
        },
      },
      [theme.breakpoints.down(381)]: {
        "&& input": {
          fontSize: "12.5px",
        },
      },
      [theme.breakpoints.down(361)]: {
        "&& input": {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down(321)]: {
        "&& input": {
          fontSize: "10px",
        },
      },
      "& label": {
        paddingLeft: "14px",
      },
      "& div": {
        margin: "0px",
        height: "100%",
        padding: "0px",
        width: "92%",
        [theme.breakpoints.down(533)]: {
          width: "90%",
        },
        [theme.breakpoints.down(480)]: {
          width: "88%",
        },
        [theme.breakpoints.down(390)]: {
          width: "87%",
        },
        [theme.breakpoints.down(360)]: {
          width: "84%",
        },
      },
      "&& p": {
        marginTop: "1px",
      },
      "&& div:before": {
        borderBottom: "none",
      },
    },
    textMobile: {
      borderRadius: "5px",
      border: "solid 1px #e4e4e4",
      margin: "0px auto 20px auto",
      display: "block",
      width: "100%",
      padding: "10px 0 0 0",
      backgroundColor: "#f1f1f1",
      "& label": {
        color: "grey",
        marginLeft: "10px",
      },
      "&& input": {
        fontSize: "20px",
        color: theme.palette.typography.texts.color,
      },
      [theme.breakpoints.down(641)]: {
        "&& input": {
          fontSize: "16px",
        },
      },
      [theme.breakpoints.down(481)]: {
        "&& input": {
          fontSize: "14px",
        },
      },
      [theme.breakpoints.down(421)]: {
        "&& input": {
          fontSize: "13.5px",
        },
      },
      [theme.breakpoints.down(401)]: {
        "&& input": {
          fontSize: "13px",
        },
      },
      [theme.breakpoints.down(381)]: {
        "&& input": {
          fontSize: "12.5px",
        },
      },
      [theme.breakpoints.down(361)]: {
        "&& input": {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down(321)]: {
        "&& input": {
          fontSize: "10px",
        },
      },
      "& div": {
        margin: "0px",
        height: "100%",
        width: "100%",
        padding: "0px 0px 0px 13px",
      },
      "&& div:before": {
        borderBottom: "none",
      },
      "&& div:hover": {
        borderBottom: "none",
      },
    },
    textEmail: {
      borderRadius: "5px",
      border: "solid 1px #e4e4e4",
      margin: "0px auto 20px auto",
      display: "block",
      width: "100%",
      padding: "10px 0 0 11px",
      "&& input": {
        fontSize: "20px",
        lineHeight: "13.5px",
      },
      [theme.breakpoints.down(641)]: {
        "&& input": {
          fontSize: "16px",
          lineHeight: "1.2",
        },
      },
      [theme.breakpoints.down(481)]: {
        "&& input": {
          fontSize: "14px",
          lineHeight: "1.11",
        },
      },
      [theme.breakpoints.down(421)]: {
        "&& input": {
          fontSize: "13.5px",
        },
      },
      [theme.breakpoints.down(401)]: {
        "&& input": {
          fontSize: "13px",
        },
      },
      [theme.breakpoints.down(381)]: {
        "&& input": {
          fontSize: "12.5px",
          lineHeight: "1.07",
        },
      },
      [theme.breakpoints.down(361)]: {
        "&& input": {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down(321)]: {
        "&& input": {
          fontSize: "10px",
          lineHeight: "13.5px",
        },
      },
      "& label": {
        paddingLeft: "14px",
      },
      "& div": {
        margin: "0px",
        height: "100%",
        width: "100%",
        padding: "0px",
      },
      "&& p": {
        marginTop: "1px",
      },
      "&& div:before": {
        borderBottom: "none",
      },
      "&& div:hover": {
        borderBottom: "none",
      },
    },
    textPincode: {
      float: "left",
      borderRadius: "5px",
      border: "solid 1px #e4e4e4",
      margin: "0px auto 20px auto",
      display: "block",
      width: "49%",
      padding: "10px 0 0 11px",
      "&& input": {
        fontSize: "20px",
        lineHeight: "13.5px",
      },
      [theme.breakpoints.down(641)]: {
        "&& input": {
          fontSize: "16px",
          lineHeight: "1.2",
        },
      },
      [theme.breakpoints.down(481)]: {
        "&& input": {
          fontSize: "14px",
          lineHeight: "1.11",
        },
      },
      [theme.breakpoints.down(421)]: {
        "&& input": {
          fontSize: "13.5px",
        },
      },
      [theme.breakpoints.down(401)]: {
        "&& input": {
          fontSize: "13px",
        },
      },
      [theme.breakpoints.down(381)]: {
        "&& input": {
          fontSize: "12.5px",
          lineHeight: "1.07",
        },
      },
      [theme.breakpoints.down(361)]: {
        "&& input": {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down(321)]: {
        "&& input": {
          fontSize: "10px",
          lineHeight: "13.5px",
        },
      },
      "& label": {
        paddingLeft: "14px",
      },
      "& div": {
        margin: "0px",
        height: "100%",
        width: "100%",
        padding: "0px",
      },
      "&& p": {
        marginTop: "1px",
      },
      "&& div:before": {
        borderBottom: "none",
      },
      "&& div:hover": {
        borderBottom: "none",
      },
    },
    submitBtn: {
      ...theme.palette.typography.doneButtons,
      width: "100%",
      height: "92px",
      fontSize: "22px",
      lineHeight: "18px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "16px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "14px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    textPincodeWrap: {
      margin: "0px",
      padding: "0px",
      display: "block",
      position: "relative",
    },
    textReferalCodeWrap: {
      margin: "0px",
      padding: "0px",
      display: "block",
      position: "relative",
    },
    textReferalCode: {
      float: "left",
      borderRadius: "5px",
      border: "solid 1px #e4e4e4",
      margin: "0px auto 20px auto",
      display: "block",
      width: "100%",
      padding: "10px 0 0 11px",
      "&& input": {
        fontSize: "20px",
        lineHeight: "13.5px",
      },
      [theme.breakpoints.down(641)]: {
        "&& input": {
          fontSize: "16px",
          lineHeight: "1.2",
        },
      },
      [theme.breakpoints.down(481)]: {
        "&& input": {
          fontSize: "14px",
          lineHeight: "1.11",
        },
      },
      [theme.breakpoints.down(421)]: {
        "&& input": {
          fontSize: "13.5px",
        },
      },
      [theme.breakpoints.down(401)]: {
        "&& input": {
          fontSize: "13px",
        },
      },
      [theme.breakpoints.down(381)]: {
        "&& input": {
          fontSize: "12.5px",
          lineHeight: "1.07",
        },
      },
      [theme.breakpoints.down(361)]: {
        "&& input": {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down(321)]: {
        "&& input": {
          fontSize: "10px",
          lineHeight: "13.5px",
        },
      },
      "& label": {
        paddingLeft: "14px",
      },
      "& div": {
        margin: "0px",
        height: "100%",
        width: "100%",
        padding: "0px",
      },
      "&& p": {
        marginTop: "1px",
      },
      "&& div:before": {
        borderBottom: "none",
      },
      "&& div:hover": {
        borderBottom: "none",
      },
    },
    selectListUl: {
      margin: "0px",
      padding: "0px",
      display: "block",
      listStyle: "none",
      position: "absolute",
      top: "42px",
      float: "right",
      right: 0,
      background: "#fffefe",
      border: "1px solid #ccc",
      height: "120px",
      overflowY: "scroll",
      zIndex: "99",
      width: "50%",
    },
    less: {
      height: "39px",
      overflow: "hidden",
      // [theme.breakpoints.down(321)]: {
      //   height: "90%",
      // },
    },
    selectListLi: {
      width: "100%",
      height: "auto",
      margin: "0px",
      padding: "10px 10px",
      display: "block",
      fontSize: "0.875rem",
      textAlign: "left",
      borderBottom: "1px solid #ccc",
      cursor: "pointer",
    },
    cssLabel: {
      color: "#aaaaaa",
      "&.focused": {
        color: "#aaaaaa",
      },
      "&.shrink": {
        transform: "translate(1px, 12px) scale(0.75)",
        [theme.breakpoints.down("481")]: {
          transform: "translate(1px, 16px) scale(0.75)",
        },
        [theme.breakpoints.down("381")]: {
          transform: "translate(1px, 14px) scale(0.75)",
        },
        [theme.breakpoints.down("321")]: {
          transform: "translate(1px, 18px) scale(0.75)",
        },
      },
    },
    cssUnderline: {
      "&:after": {
        borderBottom: "none",
      },
      "&&&&:before": {
        borderBottom: "none",
      },
    },
    input: {
      "&:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 1000px white inset",
      },
    },
    formControl: {
      "& .MuiFormLabel-root": {
        fontFamily: "Montserrat !important",
      },
      "& .MuiInputBase-input:-webkit-autofill": {
        padding: 0,
      },
      "& .MuiTextField-root": {
        height: "92px",
      },
      "& .MuiInputLabel-root": {
        marginTop: "5px",
        fontSize: "20px",
      },
      "& .MuiSelect-selectMenu": {
        padding: 0,
      },
      "& .MuiInputBase-input:focus": {
        backgroundColor: "unset",
        padding: '6px 4px 7px'
      },
      "& .MuiInputBase-input": {
        fontSize: "20px",
        color: theme.palette.typography.texts.subHeading,

        [theme.breakpoints.down(641)]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "12.5px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "10px",
        },
      },
      [theme.breakpoints.down(641)]: {
        "& .MuiTextField-root": {
          height: "80px",
        },
        "& .MuiInputLabel-root": {
          fontSize: "16px",
          marginTop: "1px",
        },
      },
      [theme.breakpoints.down(481)]: {
        "& .MuiTextField-root": {
          height: "54px",
        },
        "& .MuiInputLabel-root": {
          fontSize: "14px",
          marginTop: "-7px",
        },
        "& .MuiFormHelperText-root": {
          lineHeight: 1.3,
        },
      },
      [theme.breakpoints.down(421)]: {
        "& .MuiInputLabel-root": {
          fontSize: "13.5px",
        },
        "& .MuiFormHelperText-root": {
          lineHeight: 1.17,
        },
      },
      [theme.breakpoints.down(401)]: {
        "& .MuiInputLabel-root": {
          fontSize: "13px",
        },
      },
      [theme.breakpoints.down(381)]: {
        "& .MuiTextField-root": {
          height: "46px",
        },
        "& .MuiInputLabel-root": {
          fontSize: "12.5px",
          marginTop: "-8px",
        },
        "& .MuiFormHelperText-root": {
          fontSize: "0.623rem",
          lineHeight: 1.3,
        },
      },
      [theme.breakpoints.down(361)]: {
        "& .MuiInputLabel-root": {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down(321)]: {
        "& .MuiTextField-root": {
          height: "40px",
        },
        "& .MuiInputLabel-root": {
          marginTop: "-11px",
          fontSize: "10px",
        },
      },
    },
    // regIconCss: {
    //   backgroundColor: theme.palette.primary.main,
    //   " -webkit-mask-image": `url(.${registrationicon})`,
    //   "mask-image": `url(.${registrationicon})`,
    //   mask: `url(.${registrationicon}) no-repeat center / contain`,
    //   "-webkit-mask": `url(.${registrationicon}) no-repeat center / contain`,
    // },
    com_color: {
      color: theme.palette.typography.texts.subHeading,
    },
    com_font: {
      fontSize: "20px",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
      },
    },
    terms: {
      color: theme.palette.typography.texts.specialPointsColor,
      textDecoration: "none",
    },
    whatsappStatusContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 24,
      marginTop: "2px",
      [theme.breakpoints.down(481)]: {
        marginBottom: 16,
      },
      [theme.breakpoints.down(421)]: {
        marginBottom: 16,
        // marginTop: "4px",
      },
      [theme.breakpoints.down(401)]: {
        marginBottom: 15,
      },
      [theme.breakpoints.down(381)]: {
        marginBottom: 12,
      },
      [theme.breakpoints.down(361)]: {
        marginBottom: 12,
      },
      [theme.breakpoints.down(321)]: {
        marginBottom: 10,
      },
    },
    checkbox: {
      display: "inline-block",
      width: "40px",
      height: "40px",
      cursor: "pointer",
      [theme.breakpoints.down(481)]: {
        width: "28px",
        height: "28px",
      },
      [theme.breakpoints.down(401)]: {
        width: "27px",
        height: "27px",
      },
      [theme.breakpoints.down(381)]: {
        width: "21px",
        height: "21px",
      },
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${selected})`,
      "mask-image": `url(.${selected})`,
      mask: `url(.${selected}) no-repeat center / contain`,
      "-webkit-mask": `url(.${selected}) no-repeat center / contain`,
    },
    disable_checkbox: {
      backgroundColor: "#b6babd",
      " -webkit-mask-image": `url(.${disable_checkbox})`,
      "mask-image": `url(.${disable_checkbox})`,
      mask: `url(.${disable_checkbox}) no-repeat center / contain`,
      "-webkit-mask": `url(.${disable_checkbox}) no-repeat center / contain`,
    },
    selectInput: {
      width: "100%",
      overflow: "hidden",
      position: "relative",
      borderRadius: "5px",
      border: "solid 1px #e4e4e4",
      backgroundColor: "#fff",
      margin: "0px auto 30px auto",
      padding: "15px 10px 38.25px 11px",
      [theme.breakpoints.down("641")]: {
        padding: "15px 10px 31px 11px",
        margin: "0px auto 28px auto",
      },
      [theme.breakpoints.down("481")]: {
        margin: "0px auto 21px auto",
        padding: "15px 10px 7.39px 11px",
      },
      [theme.breakpoints.down("381")]: {
        margin: "0px auto 15px auto",
        padding: "15px 10px 1.75px 11px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13.14px 10px 0px 11px",
        margin: "0px auto 13px auto",
      },
      "& .MuiInputBase-input:focus": {
        backgroundColor: "unset",
      },
    },
    selectInputMarital: {
      width: "100%",
      overflow: "hidden",
      position: "relative",
      borderRadius: "5px",
      border: "solid 1px #e4e4e4",
      backgroundColor: "#fff",
      margin: "0px auto 20px auto",
      padding: "25px 20px 38.25px 11px",
      [theme.breakpoints.down("641")]: {
        padding: "18px 20px 31px 11px",
        // margin: "0px auto 28px auto",
      },
      [theme.breakpoints.down("481")]: {
        // margin: "0px auto 21px auto",
        padding: "18px 20px 7.39px 11px",
      },
      [theme.breakpoints.down("381")]: {
        // margin: "0px auto 15px auto",
        padding: "18px 20px  1.75px 11px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "18px 20px 10px 0px 11px",
        // margin: "18px 18px  13px auto",
      },
      "& .MuiInputBase-input:focus": {
        backgroundColor: "unset",
      },
    },
    itemContainer: {
      display: "flow-root",
      width: "100%",
      verticalAlign: "top",
      position: "relative",
    },
    inactive: {
      "& .MuiInputBase-input": {
        color: "#aaaaaa !important",
      },
    },
    padd_right: {
      paddingRight: "15px",
      [theme.breakpoints.down(641)]: {
        paddingRight: "14px",
      },
      [theme.breakpoints.down(481)]: {
        paddingRight: "10.5px",
      },
      [theme.breakpoints.down(381)]: {
        paddingRight: "7.5px",
      },
      [theme.breakpoints.down(321)]: {
        paddingRight: "6.5px",
      },
    },
    select_box: {
      float: "left",
      width: "49%",
      display: "block",
      background: "#fff",
      border: "1px solid #e4e4e4",
      borderRadius: "4px",
      position: "relative",
      margin: "0px 2% 20px 0px",
      overflow: "hidden",
      height: "92px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
      },
    },
    select_box_marital: {
      float: "left",
      width: "105%",
      display: "block",
      background: "#fff",
      border: "1px solid #e4e4e4",
      borderRadius: "4px",
      position: "relative",
      margin: "0px 2% 20px 0px",
      overflow: "hidden",
      height: "92px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
      },
    },
    select_box_area: {
      float: "left",
      width: "100%",
      display: "block",
      background: "#fff",
      border: "1px solid #e4e4e4",
      borderRadius: "4px",
      position: "relative",
      margin: "0px 2% 20px 0px",
      overflow: "hidden",
      height: "92px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
      },
    },
    tx_label_active: {
      padding: "20px 0 0px 24px",
      fontSize: "16px",
      color: "#aaaaaa",
      display: "block",
      textAlign: "left",
      fontFamily: "Montserrat !important",
      fontWeight: 400,
      lineSpacing: "0.00938em",
      lineHeight: 1,
      transformOrigin: " top left",
      [theme.breakpoints.down(641)]: {
        fontSize: "12px",
        padding: "9px 0 0px 24px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "11px",
        padding: "9px 0 0px 16px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(381)]: {
        padding: "7px 0 0px 16px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
        padding: "6px 0 0px 16px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
        padding: "6px 0 0px 16px",
      },
    },

    maritalLabel: {
      padding: "20px 0 0px 24px",
      fontSize: "16px",
      color: "#aaaaaa",
      display: "block",
      textAlign: "left",
      fontFamily: "Montserrat !important",
      fontWeight: 400,
      lineSpacing: "0.00938em",
      lineHeight: 1,
      transformOrigin: " top left",
      [theme.breakpoints.down(641)]: {
        fontSize: "12px",
        padding: "9px 0 0px 24px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "11px",
        padding: "9px 0 0px 16px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(381)]: {
        padding: "7px 0 0px 16px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
        padding: "6px 0 0px 16px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
        padding: "6px 0 0px 16px",
      },

    },
    down_icon: {
      position: "absolute",
      pointerEvents: "none",
      right: "10px",
      top: "calc(50% - 26px)",
      // [theme.breakpoints.down(641)]: {
      //   top: " calc(50% - 18px)",
      // },
      [theme.breakpoints.down(481)]: {
        top: "calc(50% - 2px)",
      },
      [theme.breakpoints.down(381)]: {
        top: " calc(50% - 4px)",
      },
      [theme.breakpoints.down(321)]: {
        top: " calc(50% - 2px)",
      },
    },
    down_icon_area: {
      position: "absolute",
      pointerEvents: "none",
      right: "-18px",

      // top: "calc(50% - 26px)",
      // [theme.breakpoints.down(641)]: {
      //   top: " calc(50% - 18px)",
      // },
      [theme.breakpoints.down(481)]: {
        top: "calc(50% - 2px)",
      },
      [theme.breakpoints.down(381)]: {
        top: " calc(50% - 4px)",
      },
      [theme.breakpoints.down(321)]: {
        top: " calc(50% - 2px)",
      },
    },
    down_icon_marital: {
      position: "absolute",
      pointerEvents: "none",
      right: "20px",
      top: '6px',
      top: "calc(50% - 26px)",
      // [theme.breakpoints.down(641)]: {
      //   top: " calc(50% - 18px)",
      // },
      [theme.breakpoints.down(481)]: {
        top: "calc(50% - 2px)",
      },
      [theme.breakpoints.down(381)]: {
        top: " calc(50% - 4px)",
      },
      [theme.breakpoints.down(321)]: {
        top: " calc(50% - 2px)",
      },
    },
    down_icon_active: {
      position: "absolute",
      pointerEvents: "none",
      right: "10px",
      top: "calc(50% - 12px)",
      // [theme.breakpoints.down(641)]: {
      //   top: " calc(50% - 18px)",
      // },
      [theme.breakpoints.down(481)]: {
        top: "calc(50% - 11px)",
      },
      [theme.breakpoints.down(381)]: {
        top: " calc(50% - 10px)",
      },
      [theme.breakpoints.down(321)]: {
        top: " calc(50% - 7px)",
      },
    },
    tx_label2: {
      padding: "17px 0 0px 24px",
      color: "#aaaaaa",
      display: "block",
      textAlign: "left",
      fontFamily: "Montserrat !important",
      fontWeight: 400,
      lineSpacing: "0.00938em",
      lineHeight: 1.5,
      transformOrigin: " top left",
      [theme.breakpoints.down(641)]: {
        padding: "10px 0 0px 24px",
      },
      [theme.breakpoints.down(481)]: {
        padding: "10px 0 0px 16px",
      },
      [theme.breakpoints.down(361)]: {
        padding: "8px 0 0px 16px",
      },
      [theme.breakpoints.down(321)]: {
        padding: "7px 0 0px 16px",
      },
    },
    dialogContainer: {

      padding: "30px",
      [theme.breakpoints.down(641)]: {
        padding: "28px",
      },
      [theme.breakpoints.down(481)]: {
        padding: "21px",
      },
      [theme.breakpoints.down(381)]: {
        padding: "15px",
      },
      [theme.breakpoints.down(321)]: {
        padding: "13px",
      },

    },
    subHeading: {
      fontSize: "18px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.64",
      letterSpacing: "normal",
      color: theme.palette.typography.texts.color,
      textAlign: 'center',
      marginBottom: "30px",
      [theme.breakpoints.down(641)]: {
        lineHeight: "1.64",
        fontSize: "16px",
        marginBottom: "28px",
      },
      [theme.breakpoints.down(481)]: {
        lineHeight: "1.5",
        fontSize: "14px",
        marginBottom: "21px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(381)]: {
        lineHeight: "1.5",
        fontSize: "13px",
        marginBottom: "15px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.6",
        marginBottom: "13px",
      },
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'space-around'
    },
    okBtn: {
      ...theme.palette.typography.doneButtons,
      marginBottom: 0,
      fontSize: "22px",
      height: "80px",
      width: '120px',
      lineHeight: "18px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "18px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "16px",
        lineHeight: "12px",
        height: "60px",
        width: '90px',
      },
      [theme.breakpoints.down(421)]: {
        height: "54px",
        fontSize: "13.5px",
        height: "40px",
        width: '50px',
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "13px",
        lineHeight: "12px",
        height: "40px",
        width: '50px',
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        lineHeight: "9px",
        height: "40px",
        width: '50px',
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        width: '50px',
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    heading: {
      fontSize: "32px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "0.94",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.typography.texts.color,
      marginBottom: "30px",
      [theme.breakpoints.down(641)]: {
        fontSize: "28px",
        lineHeight: "0.96",
        marginBottom: "28px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "18px",
        lineHeight: "0.91",
        marginBottom: "21px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "17px",
        lineHeight: "0.83",
        marginBottom: "15px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "14px",
        lineHeight: "0.84",
        marginBottom: "13px",
      },
    },

    fieldSet: {
      borderRadius: '5px',
      border: 'solid 1px #e4e4e4',
      margin: '0px auto 30px auto',
      display: 'block',
      width: '50%',
      padding: '10px 10px 0 11px',
      backgroundColor: '#fff',
      '&& input': {
        fontSize: '20px',
        lineHeight: '13.5px',
        color: color
      },
      [theme.breakpoints.down(641)]: {
        margin: '0px auto 20px auto',
        '&& input': {
          fontSize: '16px',
          lineHeight: '1.2'
        }
      },
      [theme.breakpoints.down(481)]: {
        margin: '0px auto 16px auto',
        '&& input': {
          fontSize: '14px',
          lineHeight: '1.11'
        }
      },
      [theme.breakpoints.down(421)]: {
        '&& input': {
          fontSize: '13.5px'
        }
      },
      [theme.breakpoints.down(401)]: {
        '&& input': {
          fontSize: '13px'
        }
      },
      [theme.breakpoints.down(381)]: {
        margin: '0px auto 12px auto',
        '&& input': {
          fontSize: '12.5px',
          lineHeight: '1.07'
        }
      },
      [theme.breakpoints.down(361)]: {
        '&& input': {
          fontSize: '12px'
        }
      },
      [theme.breakpoints.down(321)]: {
        margin: '0px auto 12px auto',
        '&& input': {
          fontSize: '10px',
          lineHeight: '13.5px'
        }
      },
      '& label': {
        paddingLeft: '14px'
      },
      '& div': {
        margin: '0px',
        height: '100%',
        width: '100%',
        padding: '0px'
      },
      '&& p': {
        marginTop: '1px'
      },
      '&& div:before': {
        borderBottom: 'none'
      },
      '&& div:hover': {
        borderBottom: 'none'
      }
    },
    fieldSetMaritalStatus: {
      borderRadius: '5px',
      border: 'solid 1px #e4e4e4',
      margin: '0px auto 30px auto',
      display: 'block',
      width: '105%',
      padding: '6px 10px 0 11px',
      backgroundColor: '#fff',
      '&& input': {
        fontSize: '20px',
        lineHeight: '13.5px',
        color: color
      },
      [theme.breakpoints.down(641)]: {
        margin: '0px auto 20px auto',
        '&& input': {
          fontSize: '16px',
          lineHeight: '1.2'
        }
      },
      [theme.breakpoints.down(481)]: {
        margin: '0px auto 16px auto',
        '&& input': {
          fontSize: '14px',
          lineHeight: '1.11'
        }
      },
      [theme.breakpoints.down(421)]: {
        '&& input': {
          fontSize: '13.5px'
        }
      },
      [theme.breakpoints.down(401)]: {
        '&& input': {
          fontSize: '13px'
        }
      },
      [theme.breakpoints.down(381)]: {
        margin: '0px auto 12px auto',
        '&& input': {
          fontSize: '12.5px',
          lineHeight: '1.07'
        }
      },
      [theme.breakpoints.down(361)]: {
        '&& input': {
          fontSize: '12px'
        }
      },
      [theme.breakpoints.down(321)]: {
        margin: '0px auto 12px auto',
        '&& input': {
          fontSize: '10px',
          lineHeight: '13.5px'
        }
      },
      '& label': {
        paddingLeft: '14px'
      },
      '& div': {
        margin: '0px',
        height: '100%',
        width: '100%',
        padding: '0px'
      },
      '&& p': {
        marginTop: '1px'
      },
      '&& div:before': {
        borderBottom: 'none'
      },
      '&& div:hover': {
        borderBottom: 'none'
      }
    },
    down_icon: {
      position: 'absolute',
      pointerEvents: 'none',
      right: '0px',
      top: 'calc(50% - 10px)'
    },
    itemContainer1: {
      display: 'inline-block',
      width: '50%',
      verticalAlign: 'top',
      position: 'relative'
    },
    padd_right1: {
      paddingRight: '15px',
      [theme.breakpoints.down(641)]: {
        paddingRight: '14px'
      },
      [theme.breakpoints.down(481)]: {
        paddingRight: '10.5px'
      },
      [theme.breakpoints.down(381)]: {
        paddingRight: '7.5px'
      },
      [theme.breakpoints.down(321)]: {
        paddingRight: '6.5px'
      }
    },
    padd_left1: {
      paddingLeft: '15px',
      [theme.breakpoints.down(641)]: {
        paddingLeft: '14px'
      },
      [theme.breakpoints.down(481)]: {
        paddingLeft: '10.5px'
      },
      [theme.breakpoints.down(381)]: {
        paddingLeft: '7.5px'
      },
      [theme.breakpoints.down(321)]: {
        paddingLeft: '6.5px'
      }
    },
    fieldSet: {
      borderRadius: '5px',
      border: 'solid 1px #e4e4e4',
      margin: '0px auto 30px auto',
      display: 'block',
      width: '100%',
      padding: '10px 10px 0 11px',
      backgroundColor: '#fff',
      '&& input': {
        fontSize: '20px',
        lineHeight: '13.5px',
        color: color
      },
      [theme.breakpoints.down(641)]: {
        margin: '0px auto 20px auto',
        '&& input': {
          fontSize: '16px',
          lineHeight: '1.2'
        }
      },
      [theme.breakpoints.down(481)]: {
        margin: '0px auto 16px auto',
        '&& input': {
          fontSize: '14px',
          lineHeight: '1.11'
        }
      },
      [theme.breakpoints.down(421)]: {
        '&& input': {
          fontSize: '13.5px'
        }
      },
      [theme.breakpoints.down(401)]: {
        '&& input': {
          fontSize: '13px'
        }
      },
      [theme.breakpoints.down(381)]: {
        margin: '0px auto 12px auto',
        '&& input': {
          fontSize: '12.5px',
          lineHeight: '1.07'
        }
      },
      [theme.breakpoints.down(361)]: {
        '&& input': {
          fontSize: '12px'
        }
      },
      [theme.breakpoints.down(321)]: {
        margin: '0px auto 12px auto',
        '&& input': {
          fontSize: '10px',
          lineHeight: '13.5px'
        }
      },
      '& label': {
        paddingLeft: '14px'
      },
      '& div': {
        margin: '0px',
        height: '100%',
        width: '100%',
        padding: '0px'
      },
      '&& p': {
        marginTop: '1px'
      },
      '&& div:before': {
        borderBottom: 'none'
      },
      '&& div:hover': {
        borderBottom: 'none'
      }
    },
    menuItem1: {
      fontSize: '18px',
      [theme.breakpoints.down('641')]: {
        fontSize: '16px'
      },
      [theme.breakpoints.down('481')]: {
        fontSize: '14px'
      },
      [theme.breakpoints.down('421')]: {
        fontSize: '13.5px'
      },
      [theme.breakpoints.down('401')]: {
        fontSize: '13px'
      },
      [theme.breakpoints.down('381')]: {
        fontSize: '12.5px'
      },
      [theme.breakpoints.down('361')]: {
        fontSize: '12px'
      },
      [theme.breakpoints.down('321')]: {
        fontSize: '10px'
      }
    },
    down_icon_areaName: {
      position: 'absolute',
      pointerEvents: 'none',
      right: '-10px',
      top: 'calc(50% - 10px)'
    },
  };
};
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
const gender_list = [
  { id: "0", name: "Gender", disable: true },
  { id: "1", name: "Male", disable: false },
  { id: "2", name: "Female", disable: false },
  { id: "3", name: "Other", disable: false },
];

const AreaNameList = [
  { id: "0", name: "Area Name", disable: true },
  { id: "1", name: "hgt", disable: false },
  { id: "2", name: "Nagpur", disable: false },

]
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "50%",
    },
  },
};

const CustomInput = withStyles((theme) => ({
  root: {
    width: "96%",
    position: "relative",
    padding: "0px 24px 0px 26px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 24px 0px 24px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 24px 0px 16px",
    },
    fontSize: "20px",
    [theme.breakpoints.down(641)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down(481)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(421)]: {
      fontSize: "13.5px",
    },
    [theme.breakpoints.down(401)]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down(381)]: {
      fontSize: "12.5px",
    },
    [theme.breakpoints.down(361)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(321)]: {
      fontSize: "10px",
    },
  },
}))(InputBase);

const maritalStatus = [
  { id: "0", name: "Marital Status", disable: true },
  { id: "1", name: "Single", disable: false },
  { id: "2", name: "Married", disable: false },
  { id: "3", name: "Widow", disable: false },
];
const CustomTextField1 = withStyles((theme) => ({
  root: {
    width: "96%",
    textAlign: "left",
    fontSize: "20px",
    display: "block",

    [theme.breakpoints.down(641)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down(481)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(421)]: {
      fontSize: "13.5px",
    },
    [theme.breakpoints.down(401)]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down(381)]: {
      fontSize: "12.5px",
    },
    [theme.breakpoints.down(361)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(321)]: {
      fontSize: "10px",
    },

  },
}))(InputBase);

const CustomTextField = withStyles((theme) => ({
  root: {
    width: "96%",
    position: "relative",
    textAlign: "left",
  },
}))(TextField);


class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      helperText: "",
      helperTextFname: "",
      helperTextLname: "",
      error: false,
      disabled: false,
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      pincode: "",
      pincodeId: 0,
      pinList: [],
      pinCodeDiv: false,
      emailError: false,
      fnameError: false,
      lnameError: false,

      isNewRegister: 1,
      customerId: null,

      currency_code: null,
      points_unit_short_name: "",
      loyalty_id: "",
      whatsappStatus: true,

      gender: 0,
      status: 1,

      areaName: 0,
      areaNameID: 0,
      maritalStatus: 1,
      spouseDOB: null,
      anniversaryDate: null,
      dob: null,
      dobError: false,
      areaNameList: [],

      helperTextDOB: "",

      referalCode: '',
      isReferalBoxDisable: false,

      IsModalVisible: false,
    };
  }

  componentDidMount() {
    this.restoredData();
    this.clearPincode();
    setStorageItem("edit-profile-redirection", "0");
    this.setState(
      {
        isReferalBoxDisable: getStorageItem("referalCode") == "false" ? false : true,
        referalCode: getStorageItem("referalCode") === "false" ? "" : getStorageItem("referalCode"),

        mobile:
          this.props.history.location.state &&
          this.props.history.location.state.usermobile,
      },
      () => {
        if (this.state.mobile !== "") {
        const data = {
            merchant_id: apiData.merchant_id,
            mobile_number:  this.state.mobile
          }
          let encryptedPayload ={
            _k: encrypt(JSON.stringify(data))
          }
          stockAPI(encryptedPayload, "POST", "/checkCustomerData").then(
            async (response) => {
              if (
                response &&
                response.isRegisterComplete &&
                response.isRegisterComplete === "0" &&
                response.customer !== ""
              ) {
                await this.setState({
                  isNewRegister: 0,
                  firstName: response.customer.first_name
                    ? response.customer.first_name
                    : "",
                  lastName: response.customer.last_name
                    ? response.customer.last_name
                    : "",
                  mobile: response.customer.mobile_number
                    ? response.customer.mobile_number
                    : "",
                  email: response.customer.email_address
                    ? response.customer.email_address
                    : "",
                  gender: response.customer.gender && response.customer.gender !== '' && response.customer.gender !== 0 ?
                    response.customer.gender
                    : 0,
                  pincode: response.customer.pin_code
                    ? response.customer.pin_code
                    : "",
                  pincodeId:
                    response.customer.pin_code_id !== ""
                      ? response.customer.pin_code_id
                      : 0,
                  customerId: response.customer.customer_id,
                  status: response.customer.status,
                  areaName: response && response?.customer?.area_code_id ? response?.customer?.area_code_id : 0,
                  areaNameID: response && response?.customer?.area_code_id ? response?.customer?.area_code_id : 0,
                  maritalStatus: response && response?.customer?.marital_status ? response?.customer?.marital_status : 0,
                  spouseDOB: response && response?.customer?.spouse_dob ? response?.customer?.spouse_dob : null,
                  anniversaryDate: response && response?.customer?.anniversary_date ? response?.customer?.anniversary_date : null,
                  dob: response && response?.customer?.date_of_birth ? response?.customer?.date_of_birth : null,
                });
                this.getAreaName(response.customer.pin_code_id !== ""
                  ? response.customer.pin_code_id
                  : 0);
              }
            }
          );
        }
      }
    );
    setStorageItem("referalCode", "");

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.customerId && prevState.customerId === null) {
      const data = new FormData();
      data.append("platform", apiData.platform);
      data.append("userId", this.state.customerId);
      data.append("merchant_id", apiData.merchant_id);
      data.append("customer_id", this.state.customerId);
      // data.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);

      stockAPI(data, "POST", "/getMerchantDetails").then(async (response) => {
        if (
          response &&
          response.merchant_list &&
          response.merchant_list &&
          response.merchant_list.added &&
          response.merchant_list.added[1]
        ) {
          await this.setState({
            currency_code:
              response.merchant_list.added[1].loyalty_currency_code,
            points_unit_short_name:
              response.merchant_list.added[1].points_unit_short_name,
            loyalty_id: response.merchant_list.added[1].loyalty_id,
          });
        } else {
          setTimeout(() => {
            this.props.logoutUser();
            this.props.history.push("/");
          }, 6000);
        }
      });
    }
    setStorageItem("gender", 0);
  }

  handleFname = (e) => {
    const re = /^([a-zA-Z ]+)?([[a-zA-Z'-]{0,1})?[a-zA-Z]+$/;
    let value = e.target.value;
    let errorMessage = "";
    let errorMessageStatus = false;

    if (value.length === 0) {
      errorMessage = "First name is required.";
      errorMessageStatus = true;
    } else {
      if (value.length > 20) {
        return false;
      }
      if (re.test(value)) {
        e.preventDefault();
        errorMessage = "";
        errorMessageStatus = false;
        value = capitalize(value);
      } else {
        errorMessage = "Please enter valid name.";
        errorMessageStatus = true;
      }
    }

    this.setState({
      firstName: value,
      helperTextFname: errorMessage,
      fnameError: errorMessageStatus,
    });
    const f_name = value.trim();
    setStorageItem("f_name", f_name);
  };

  handleLname = (e) => {
    const re = /^([a-zA-Z ]+)?([[a-zA-Z'-]{0,1})?[a-zA-Z]+$/;
    let value = e.target.value;
    let errorMessage = "";
    let errorMessageStatus = false;

    if (value.length === 0) {
      errorMessage = "Last name is required.";
      errorMessageStatus = true;
    } else {
      if (value.length > 20) {
        return false;
      }
      if (re.test(value)) {
        e.preventDefault();
        errorMessage = "";
        errorMessageStatus = false;
        value = capitalize(value);
      } else {
        errorMessage = "Please enter valid name.";
        errorMessageStatus = true;
      }
    }
    this.setState({
      lastName: value,
      helperTextLname: errorMessage,
      lnameError: errorMessageStatus,
    });
    const l_name = value.trim();

    setStorageItem("l_name", l_name);
  };

  onPinchange = async (e) => {
    const re = /^[0-9\b]+$/;
    this.setState({ pincode: e.target.value, pincodeId: 0 });

    if (!e.target.value || e.target.value.length === 0) {
      this.setState({
        helperText: "Pincode is required.",
        error: true,
        pinCodeDiv: false,
        pinList: [],
      });
    } else if (re.test(e.target.value)) {
      this.setState({ helperText: "", error: false });
      if (e.target.value.length > 2) {
        const formData = new URLSearchParams();
        formData.append("platform", apiData.platform);
        formData.append("pin_code", e.target.value);
        formData.append("isMasterPincodeRequired", 0);

        stockAPI(formData, "POST", "/getPincode", null, null, null, true)
          .then(async (response) => {
            if (response && response.statusCode === 100) {
              this.setState({ pinList: response.response, pinCodeDiv: true });
            }
            // else {
            //   openAlertBox('Oops something went wrong!', 'error');
            // }
            if (response && response.statusCode === 102) {
              this.setState({
                helperText: response.statusDescription,
                error: true,
                pinCodeDiv: false,
              });
            }
          })
          .catch(function (err) {
            openAlertBox(err && err.message, "error");
          });
      } else {
        if (this.state.pinList.length !== 0) {
          this.setState({
            pinList: [],
            pinCodeDiv: false,
          });
        }
      }
    } else {
      this.setState({
        helperText: "Please enter numeric value.",
        error: true,
        pinList: [],
        pinCodeDiv: false,
      });
    }
  };

  pincodeValue = (e) => {
    this.setState({
      pincode: e.target.value,
      pincodeId: e.target.id,
      pinCodeDiv: false,
    });
    setStorageItem("pin_code", e.target.value);
    setStorageItem("pin_codeId", e.target.id);
    this.getAreaName(e.target.id);
  };

  handleChange = (event) => {
    const email = event.target.value.toLowerCase();
    setStorageItem("e_mail", email);
    if (event.target.value.length > 225) {
      return false;
    }
    this.setState({ email });
    if (validateEmail(email)) {
      this.setState({ helperTextEmail: "", emailError: false });
    } else {
      this.setState({
        helperTextEmail: "Please enter a valid email address.",
        emailError: true,
      });
    }
  };

  submitButton = () => {
    if (this.state.isNewRegister === 1) {
      const formData = {
        transactionType: 202,
        platform: apiData.platform,
        mobile_verified: 1,
        first_name: this.state.firstName.trim(),
        last_name: this.state.lastName.trim(),
        mobile_number: this.state.mobile,
        email_address: this.state.email,
        gender: this.state.gender,
        pin_code: this.state.pincodeId, // use pincode id in  pincode api
        merchant_id: apiData.merchant_id, // static merchant id
        date_of_birth: this.state.dob,
        marital_status: this.state.maritalStatus,
        anniversary_date: this.state.anniversaryDate,
        spouse_dob: this.state.spouseDOB,
        area_code_id: this.state.areaNameID,
        is_notifications_updates: 0,
        opt_out_from_sms_status:0,
        opt_out_from_email_status: this.state.whatsappStatus ? 0 : 1,
        referral_coupon_code: this.state.referalCode,
      };

      let encryptedPayload = {
        _k: encrypt(JSON.stringify(formData)),
      };
      this.props.registerCustomer(encryptedPayload, this.props.history);
    } else if (
      this.state.isNewRegister === 0 &&
      this.state.customerId !== null
    ) {
      
      const formData = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        mobile_number: this.state.mobile,
        email_address: this.state.email,
        gender: this.state.gender,
        pin_code: this.state.pincodeId,
        transactionType: 201,
        mobile_verified: 1,
        customer_id: this.state.customerId,
        appVersion: apiData.appVersion,
        merchant_id: apiData.merchant_id,
        platform: apiData.platform,
        date_of_birth: this.state.dob,
        marital_status: this.state.maritalStatus,
        anniversary_date: this.state.anniversaryDate,
        spouse_dob: this.state.spouseDOB,
        area_code_id: this.state.areaNameID,

        is_notifications_updates: 0,
        opt_out_from_sms_status:0,
        opt_out_from_email_status: this.state.whatsappStatus ? 0 : 1,
        status: this.state.status,
      };

      let encryptedPayload = {
        _k: encrypt(JSON.stringify(formData)),
      };

      setStorageItem("referalCode", "");
      stockAPI(encryptedPayload, "POST", "/profileUpdate").then(async (response) => {
        if (response) {
          const user = {
            id: this.state.customerId,
            mobile_number: this.state.mobile,
          };
          await setStorageItem("user", JSON.stringify(user));
          await setStorageItem("currencyCode", this.state.currency_code);
          await setStorageItem(
            "pointShortName",
            this.state.points_unit_short_name
          );
          await setStorageItem("loyaltyId", this.state.loyalty_id);
          this.props.authUser(true);
          localStorage.removeItem("formData");
          if (getStorageItem("prev_route")) {
            this.props.history.push(getStorageItem("prev_route"));
            removeStorageItem("prev_route");
          } else {
            this.props.history.push("/dashboard");
            customerLoginLogs(); formData.append("is_profile_updated_bonus", this.state.is_profile_updated_bonus);

          }
          isPWAInstalled(this.state.mobile);
        }
      });
    }
  };

  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{4})(\d{3})(\d{3})$/);
    if (match) {
      return match[1] + " " + match[2] + " " + match[3];
    }
    return null;
  };

  componentWillUnmount() {
    const { history } = this.props;
    if (history.location.pathname !== "/terms-of-use") {
      localStorage.removeItem("f_name");
      localStorage.removeItem("l_name");
      localStorage.removeItem("e_mail");
      localStorage.removeItem("pin_code");
      localStorage.removeItem("pin_codeId");
    }
  }
  clearPincode = () => {
    this.setState({
      pincodeId: 0,
      pincode: "",
    });
    setStorageItem("pin_code", "");
    // setStorageItem("pin_codeId", 0);
    setStorageItem("gender", 0);
  };
  onReferalCodeChange(e) {
    this.setState({ referalCode: e.target.value });
  }
  handleCheckBoxClick = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      whatsappStatus: !prevState.whatsappStatus,
    }));
  };
  restoredData = () => {
    const pin_codeId = getStorageItem("pin_codeId") || "";
    const f_name = getStorageItem("f_name") || "";
    const l_name = getStorageItem("l_name") || "";
    const e_mail = getStorageItem("e_mail") || "";
    const pin_code = getStorageItem("pin_code") || "";
    const gender = getStorageItem("gender") || 0;
    if (
      f_name ||
      l_name ||
      e_mail ||
      pin_codeId ||
      pin_code ||
      this.state.pincodeId === 0
    ) {
      this.setState({
        pincodeId: pin_codeId,
        firstName: f_name,
        lastName: l_name,
        email: e_mail,
        pincode: pin_code,
        gender: gender,
      });
    }
  };

  handleGender = (e) => {
    e.preventDefault();
    setStorageItem("gender", e.target.value);
    this.setState({
      gender: e.target.value,
    });
  };

  handleAreaName = (e) => {
    e.preventDefault();
    setStorageItem("areaName", e.target.value);
    this.setState({
      areaName: e.target.value,
      areaNameID: e.target.value
    });
  };
  handleDropDownMaitalStatus = (e) => {
    const { name, value } = e.target;

    if (value == 2) {
      this.setState({
        maritalStatus: value,
      });
    } else {
      this.setState({
        maritalStatus: value,
        spouseDOB: null,
        anniversaryDate: null,
      });
    }
  };
  handleDateChange1 = (date) => {
    this.setState({
      spouseDOB: moment(date._d).format('YYYY-MM-DD'),
    });
  };
  handleDateChange = (date) => {
    this.setState({
      anniversaryDate: moment(date._d).format('YYYY-MM-DD'),
    });
  };

  handleDateChange2 = (date) => {

    this.setState({
      dob: moment(date._d).format('YYYY-MM-DD'),
    });
  };

  getAreaName = (pincodeid) => {
    let formData = new URLSearchParams();
    formData.append("pincode_id", pincodeid);

    stockAPI(formData, "POST", "/getAreaCode", null, null, null, true)
      .then(async (response) => {

        if (response && response.statusCode === 100) {
          this.setState({ areaNameList: response?.response });
        }

        if (response && response.statusCode === 102) {
          this.setState({
            helperText: response.statusDescription,
            error: true,
            // pinCodeDiv: false,
          });
        }
      })
      .catch(function (err) {
        openAlertBox(err && err.message, "error");
      });
  }
  validationForMaritalStatus = () => {
    if (this.state.maritalStatus != '2') {
      return true
    } else {
      if (this.state.maritalStatus == '2') {
        if (!this.state.spouseDOB || !this.state.anniversaryDate) {
          return false;
        }
        return true

      }
    }
  }
  maxYearValidation = (diff) => {
    let crDate = moment().format('YYYY');
    let result = parseInt(crDate) - parseInt(diff ? diff : 0);
    return result && result;
  }
  render() {
    if (
      !this.props.history.location.state ||
      !this.props.history.location.state.usermobile
    ) {
      this.props.history.replace("/");
    }
    if (!this.props.isUserVisited) {
      this.props.history.replace("/");
    }

    const { classes } = this.props;
    const {
      pinList,
      firstName,
      lastName,
      email,
      pincode,
      error,
      gender,
      emailError,
      fnameError,
      lnameError,
      pincodeId,
      referalCode,
    } = this.state;


    const isSubmitEnabled =
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      gender > 0 &&
      pincode.toString().length > 5 &&
      !error &&
      !emailError &&
      !fnameError &&
      !lnameError &&
      pincodeId !== 0 &&
      this.validationForMaritalStatus() &&
      this.state.dob &&
      this.state.areaName != 0;


    return (
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        libInstance={moment}
        locale={"en"}
      >
        <div className={classes.wrapper}>
          {/* <span className={classes.icons + " " + classes.regIconCss} /> */}
          <img src={registrationicon} alt="register" className={classes.icons} />
          <Typography variant="h1" className={classes.heading}>
            Register Yourself
        </Typography>

          <ValidatorForm
            ref="form"
            onSubmit={this.submitButton}
            onError={(errors) => console.log(errors)}
            className={classes.formControl}
          >
            <div className={classes.textWrap}>
              <TextValidator
                InputProps={{
                  classes: {
                    underline: classes.cssUnderline,
                    input: classes.input,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                className={classes.textFname}
                label="First Name"
                onChange={(e) => this.handleFname(e)}
                value={firstName}
                validators={["required"]}
                errorMessages={[
                  "This field is required.",
                  "First name is required.",
                ]}
                helperText={this.state.helperTextFname}
                error={this.state.fnameError}
                inputProps={{
                  maxLength: 20,
                }}
                FormHelperTextProps={{ style: { marginLeft: "10px" } }}
                autoComplete="off"
              />
              <TextValidator
                InputProps={{
                  classes: {
                    underline: classes.cssUnderline,
                    input: classes.input,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                className={classes.textLname}
                label="Last Name"
                onChange={(e) => this.handleLname(e)}
                value={lastName}
                validators={["required"]}
                errorMessages={[
                  "This field is required.",
                  "Last Name is required.",
                ]}
                helperText={this.state.helperTextLname}
                error={this.state.lnameError}
                inputProps={{
                  maxLength: 20,
                }}
                FormHelperTextProps={{ style: { marginLeft: "10px" } }}
                autoComplete="off"
              />
            </div>

            <div>
              <TextValidator
                InputProps={{
                  classes: {
                    underline: classes.cssUnderline,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                className={classes.textMobile}
                disabled
                label="Mobile Number"
                value={"+91 " + this.formatPhoneNumber(this.state.mobile)}
                autoComplete="off"
              />
            </div>

            <div>
              <TextValidator
                InputProps={{
                  classes: {
                    underline: classes.cssUnderline,
                    input: classes.input,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                className={classes.textEmail}
                label="Email Address"
                onChange={this.handleChange}
                name="email"
                value={email}
                validators={["required"]}
                errorMessages={[
                  "This field is required.",
                  "Enter a valid email address.",
                ]}
                helperText={this.state.helperTextEmail}
                error={this.state.emailError}
                inputProps={{
                  maxLength: 100,
                }}
                autoComplete="off"
              />
            </div>
            <div className={classes.itemContainer}>
              {/* <div
              className={classnames(classes.itemContainer, {
                [classes.inactive]: !this.state.gender,
              })}
            > */}
              <div className={classes.textPincodeWrap}>
                <div className={classes.select_box}>
                  <FormControl fullWidth>
                    {this.state.gender ? (
                      <label htmlFor="gender" className={classes.tx_label_active}>
                        Gender
                      </label>
                    ) : (
                      <div className={classes.tx_label2}></div>
                    )}
                    <Select
                      native
                      name="gender"
                      labelId="controlled-gender-select-label"
                      id="controlled-gender-select"
                      value={this.state.gender}
                      onChange={this.handleGender}
                      input={<CustomInput />}
                      IconComponent={() => (
                        <img
                          className={classes.down_icon}
                          src={down_arrow}
                          alt=""
                          width="10"
                          height="6"
                        />
                      )}

                    >
                      {gender_list?.map((gen) => (
                        <option
                          disabled={gen.disable}
                          className={classes.menuItem}
                          key={gen.id}
                          value={gen.id}
                        >
                          {gen.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <TextValidator
                  className={classes.textPincode}
                  label="Pincode"
                  value={pincode}
                  validators={["required"]}
                  errorMessages={["Pincode is required"]}
                  inputProps={{
                    maxLength: 6,
                    type: "tel",
                  }}
                  onFocus={(e) => this.clearPincode(e)}
                  InputProps={{
                    classes: {
                      underline: classes.cssUnderline,
                      input: classes.input,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: "focused",
                      shrink: "shrink",
                    },
                  }}
                  onChange={(e) => this.onPinchange(e)}
                  helperText={this.state.helperText}
                  error={this.state.error}
                  autoComplete="off"
                />

                {this.state.pinCodeDiv === true ? (
                  <ul
                    className={classnames(classes.selectListUl, {
                      [classes.less]: pinList.length === 1,
                    })}
                  >
                    {pinList.length > 0 &&
                      pinList.map((elem) => {
                        return (
                          <li
                            key={elem.id}
                            id={elem.id}
                            value={elem.pc}
                            className={classnames(classes.selectListLi, {
                              [classes.less]: pinList.length === 1,
                            })}
                            onClick={(e) => {
                              this.pincodeValue(e);
                            }}
                          >
                            {elem.pc}
                          </li>
                        );
                      })}
                  </ul>
                ) : null}
                <div className={classes.selectInputMarital}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      className={classes.selectLabel}
                      id="demo-controlled-open-select-label1"
                      style={{
                        // width: "100%",
                        display: "block",
                        whiteSpace: "nowrap",
                        color: grey,
                        margin: "-30px 0px 0px 4px",
                      }}
                    >
                      {this.state.areaNameList.length == 0 ? 'Area Name' : "  "}
                    </InputLabel>

                    <Select
                      native
                      name="areaName"
                      disabled={this.state.areaNameList.length == 0 ? true : false}
                      labelId="demo-controlled-open-select-label1"
                      id="demo-controlled-open-select1"
                      value={this.state.areaName}
                      onChange={this.handleAreaName}
                      style={{ marginTop: "-10px", marginLeft: '5px' }}
                      input={<CustomTextField1 />}

                      IconComponent={() => (
                        <img
                          className={classes.down_icon_area}
                          src={down_arrow}
                          alt=""
                          width="10"
                          height="6"
                        />
                      )}
                      MenuProps={MenuProps}
                    >
                      <option
                        disabled={true}
                        className={classes.menuItem}
                        key={0}
                        value={0}
                      >
                        Select Area
                        </option>
                      {this.state.areaNameList && this.state.areaNameList.map((ms) => (
                        <option
                          disabled={ms.disable}
                          className={classes.menuItem}
                          key={ms.id}
                          value={ms.id}
                        >
                          {ms.area_name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {/*                 
                 <div className={classes.selectInput} >
                  <FormControl fullWidth>
                     <InputLabel
                      className={classes.selectLabel}
                      id="demo-controlled-open-select-label1"
                      style={{
                        // width: "100%",
                        display: "block",
                        whiteSpace: "nowrap",
                        color: '#aaaa',

                        margin: "-22px 0px 0px 0px",
                      }}
                    >
                      {this.state.areaNameList.length == 0 ? 'Area Name' : ""}
                    </InputLabel> 
                    <label htmlFor="Marital Status"
                      // style={{ marginLeft: "-1px" }}
                      className={classes.maritalLabel}>
                      Area Name
                      </label>
                    <Select
                      native

                      disabled={this.state.areaNameList.length == 0 ? true : false}
                      name="area-name"
                      labelId="demo-controlled-open-select-label1"
                      id="demo-controlled-open-select1"
                      value={this.state.areaName}
                      onChange={(e) => this.handleAreaName(e)}
                      input={<CustomTextField1 />}

                      IconComponent={() => (
                        <img
                          className={classes.down_icon_areaName}
                          src={down_arrow}
                          alt=""
                          width="10"
                          height="6"
                        />
                      )}
                      MenuProps={MenuProps}
                    >
                      {this.state.areaNameList && this.state.areaNameList?.map((gen) => (
                        <option
                          disabled={gen.disable}
                          className={classes.menuItem}
                          key={gen.id}
                          value={gen.id}
                        >
                          {gen.area_name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
            
             
             */}
                <Fragment>

                  <div
                    style={{ position: "relative" }}
                    className={
                      classes.itemContainer1 + " " + classes.padd_right1}
                  >
                    <DatePicker
                      autoOk
                      format="DD - MMM - yyyy"
                      value={this.state.dob}
                      onChange={this.handleDateChange2}
                      disableFuture
                      validators={["required"]}
                      errorMessages={["Date of Birth is required"]}
                      error={this.state.dobError}
                      maxDate={`${this.maxYearValidation(15)}-12-31`}
                      TextFieldComponent={(props) => {
                        return (
                          <CustomTextField
                            {...props}
                            id="dob"
                            inputProps={{
                              style: {
                                padding: 0,
                              },
                              autoComplete: "off",
                            }}
                            className={classes.fieldSet}
                            InputProps={{ disableUnderline: true }}
                            InputLabelProps={{
                              classes: {
                                root: classes.cssLabel,
                                focused: "focused",
                                shrink: "shrink",
                              },
                            }}
                            label="Date of Birth"
                          />
                        );
                      }}
                    />
                  </div>

                  <div
                    style={{ position: "relative" }}
                    className={
                      classes.itemContainer1 + " " + classes.padd_right1}
                  >
                    <div className={classes.select_box_marital}>

                      <label htmlFor="Marital Status"
                        style={{ marginLeft: "-1px" }}
                        className={classes.maritalLabel}>
                        Marital Status
                      </label>
                      <Select
                        native
                        name="maritalStatus"
                        labelId="demo-controlled-open-select-label1"
                        id="demo-controlled-open-select1"
                        value={this.state.maritalStatus}
                        onChange={this.handleDropDownMaitalStatus}
                        input={<CustomTextField1 />}
                        style={{ marginLeft: "18px" }}
                        IconComponent={() => (
                          <img
                            className={classes.down_icon_marital}
                            style={{ top: '3px' }}
                            src={down_arrow}
                            alt=""
                            width="10"
                            height="6"
                          />
                        )}
                        MenuProps={MenuProps}
                      >
                        {maritalStatus && maritalStatus.map((ms) => (
                          <option
                            disabled={ms.disable}
                            className={classes.menuItem}
                            key={ms.id}
                            value={ms.id}
                          >
                            {ms.name}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>


                </Fragment>

                {this.state.maritalStatus == "2" && (
                  <Fragment>
                    <div
                      style={{ position: "relative" }}
                      className={
                        classes.itemContainer1 + " " + classes.padd_right1
                      }
                    >
                      <DatePicker
                        autoOk
                        format="DD - MMM - yyyy"
                        value={this.state.spouseDOB}
                        onChange={this.handleDateChange1}
                        disableFuture
                        maxDate={`${this.maxYearValidation(18)}-12-31`}
                        TextFieldComponent={(props) => {
                          return (
                            <CustomTextField
                              {...props}
                              id="spouseDOB"
                              inputProps={{
                                style: {
                                  padding: 0,
                                },
                                autoComplete: "off",
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: "focused",
                                  shrink: "shrink",
                                },
                              }}
                              className={classes.fieldSet}
                              InputProps={{ disableUnderline: true }}
                              label="Spouse DoB"
                            />
                          );
                        }}
                      />
                    </div>
                    <div
                      className={
                        classes.itemContainer1 + " " + classes.padd_left1
                      }
                    >
                      <DatePicker
                        autoOk
                        format="DD - MMM - yyyy"
                        value={this.state.anniversaryDate}
                        onChange={this.handleDateChange}
                        disableFuture
                        TextFieldComponent={(props) => {
                          return (
                            <CustomTextField
                              {...props}
                              id="anniversaryDate"
                              inputProps={{
                                style: {
                                  padding: 0,
                                },
                                autoComplete: "off",
                              }}
                              className={classes.fieldSet}
                              InputProps={{ disableUnderline: true }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: "focused",
                                  shrink: "shrink",
                                },
                              }}
                              label="Anniversary Date"
                            />
                          );
                        }}
                      />
                    </div>

                  </Fragment>

                )}


                {this.props.isReferalBoxEnable ?
                  <div className={classes.textReferalCodeWrap}>
                    <TextValidator
                      className={classes.textReferalCode}
                      label="Promo/Referral Code"
                      value={this.state.referalCode == 'false' ? "" : this.state.referalCode}
                      // validators={["required"]}
                      disabled={this.state.isReferalBoxDisable}
                      errorMessages={["referal code is required",]}
                      inputProps={{
                        maxLength: 10,
                      }}
                      // onFocus={(e) => this.clearPincode(e)}
                      InputProps={{
                        classes: {
                          underline: classes.cssUnderline,
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: "focused",
                          shrink: "shrink",
                        },
                      }}
                      onChange={(e) => this.onReferalCodeChange(e)}
                      autoComplete="off"
                    />
                  </div>
                  : null}

              </div>
              {/* </div> */}
            </div>

           {/*  <div className={classes.whatsappStatusContainer}>
              <div style={{ marginRight: "10px" }}>
                <span
                  onClick={this.handleCheckBoxClick}
                  className={`${classes.checkbox} ${this.state.whatsappStatus
                    ? classes.selected
                    : classes.disable_checkbox
                    }`}
                />
              </div>
              <div>
                <Typography
                  onClick={this.handleCheckBoxClick}
                  className={classes.com_font + " " + classes.com_color}
                  style={{ textAlign: "left" }}
                >
                  I agree to receive notifications and updates via WhatsApp
              </Typography>
              </div>
            </div>
            */}
             <div className={classes.whatsappStatusContainer}>
            <div>
              <Typography
                className={classes.com_font + " " + classes.com_color}
                style={{ textAlign: "left" }}
              >
                I agree to receive mall-related offers and updates via
              </Typography>
            </div>
        </div>

        <div style={{ display: "flex", gap: "10px", marginBottom:"20px"}}>
              <span
                className={`${classes.checkbox} ${classes.selected}`}
                style={{
                  cursor: 'not-allowed',
                  opacity: 0.5,
                  pointerEvents: 'none',
                  color: '#a0a0a0'           
                }}
              />
              <span style={{ display: "flex", alignItems: "center", marginRight:"25px" }} className={classes.com_font + " " + classes.com_color}>WhatsApp</span>
       
              <span
                className={`${classes.checkbox} ${classes.selected}`}
                style={{
                  cursor: 'not-allowed',
                  opacity: 0.5,
                  pointerEvents: 'none',
                  color: '#a0a0a0'           
                }}
              />
              <span style={{ display: "flex", alignItems: "center", marginRight:"25px" }} className={classes.com_font + " " + classes.com_color}>SMS</span>

              <span
                onClick={this.handleCheckBoxClick}
                className={`${classes.checkbox} ${this.state.whatsappStatus
                  ? classes.selected
                  : classes.disable_checkbox
                  }`}
              />
              <span style={{ display: "flex", alignItems: "center", marginRight:"25px" }}  onClick={this.handleCheckBoxClick} className={classes.com_font + " " + classes.com_color}>Email</span>

        </div>
            <Button
              type="submit"
              className={classes.submitBtn}
              disabled={!isSubmitEnabled}
            >
              SUBMIT
          </Button>
            <Typography className={classes.com_font + " " + classes.com_color}>
              By registering you agree to our{" "}
              <Link
                to={{
                  pathname: "/terms-of-use",
                  state: { fromRegistration: true },
                }}
                className={classes.terms}
              >
                terms and conditions
            </Link>
            </Typography>
          </ValidatorForm>

          {/* <Dialog
          open={this.state.IsModalVisible}
          maxWidth="lg"
        >
          <div className={classes.dialogContainer}>

            <p className={classes.subHeading}> this code has been used maximum times.
            you will not get any points for this referral code,do you still want to continue?</p>
            <div className={classes.btnContainer}>
              <div>
                <Button
                  onClick={() => {

                  }}
                  className={classes.okBtn}>
                  YES
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    this.setState({ IsModalVisible: false })
                  }}
                  className={classes.okBtn}>
                  NO
                </Button>
              </div>


            </div>

          </div>
        </Dialog> */}
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(Registration)
);
